(function($) {

	$.fn.initSimpleTextBlock = function() {
		var _this = this;

		this.find('.big').waypoint(function(e) {
			$(_this).addClass('scrolled').removeClass('unscrolled');
		}, {
			offset: function() {
				return Waypoint.viewportHeight()  - 60;
			}
		});

		$(_this).addClass('unscrolled');

		return this;
	}

})(jQuery);
