(function($) {

	$.fn.initDrone = function() {

		var _this = this;

		_this.parallax({
			imageSrc: _this.attr("data-px-image"),
			speed: 0.3
		});

		return this;

	}

})(jQuery);
