(function($) {

	$.fn.initNavbar = function() {
		var _this = this;
		this.find(".languages .current").click(function() {
			_this.find(".languages").toggleClass("open");
			return false;
		});

		this.find(".menu-mobile a").click(function() {
			_this.toggleClass('open-mobile-menu');
			return false;
		});

		return this;
	}

})(jQuery);
