(function($) {

	function loadImageImmediately($container, source) {
		$container.css("background-image", "url('" + source + "')")
		$container.addClass("initialised");
		$container.removeClass("preloading");
		initParallax($container, source);
	}

	function preparePreloader($container) {
		$container.addClass("preloading");
	}

	function loadImageFromPreloader($container, source) {
		window.setTimeout(function() {
			$container.addClass("initialised");
			$container.removeClass("will-initialize");
		}, 200);
		window.setTimeout(function() {
			initParallax($container, source);
		}, 900)
		$container.addClass("will-initialize");
		$container.removeClass("preloading");
		$container.css("background-image", "url('" + source + "')")
	}

	function initParallax($container, source) {
		$container.css("background", "transparent");
		$container.parallax({
			imageSrc: source,
			speed: 0.4
		});
	}

	function showTexts($hero) {
		$hero.removeClass('loading').addClass('init');
	}


	$.fn.initHero = function() {

		var $hero = this;

		$hero.addClass('loading');

		this.each(function() {

			var $image = $(this).find('.image');
			var source = $image.attr("data-bg");

			var image = new Image();
			image.src = source;
			var isLoaded = false;
			var doAfterLoad = null;
			image.onload = function() {
				isLoaded = true;
				if (doAfterLoad) {
					doAfterLoad();
				}
			}
			image.onerror = function() {
				isLoaded = true;
				if (doAfterLoad) {
					doAfterLoad();
				}
			}

			window.setTimeout(function() {
				if (isLoaded && !$hero.is('.always-animate')) {
					loadImageImmediately($image, source);
					showTexts($hero);
				} else {
					if (image.complete) {
						loadImageFromPreloader($image, source);
						showTexts($hero);
					} else {
						preparePreloader($image);
						doAfterLoad = function() {
							loadImageFromPreloader($image, source);
							showTexts($hero);
						};
					}
				}
			}, 100);


		});

		$hero.find(".arrow a").click(function() {
			var target;
			var href = $(this).attr("href");
			if (href && href != '#') {
				target = $(href);
			}
			if (!target || !target.length) {
				target = $hero.next();
				if (!target.length) {
					target = $hero.parent().next();
				}
				if (!target.length) {
					target = $hero.parent().parent().next();
				}
			}
			$(window).scrollTo(target, 500);
			return false;
		})

		return this;
	}

})(jQuery);
