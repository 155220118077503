(function($) {

	$.fn.initGridMenu = function() {

		this.each(function() {

			$(this).find(".item").addClass('unscrolled').waypoint(function() {
				$(this.element).addClass('scrolled').removeClass('unscrolled');
			}, {
				offset: function() {
					return Waypoint.viewportHeight() - 150;
				}
			});

		});

		return this;

	};

})(jQuery);
