(function($) {

	var pswpSource = '<div class="pswp" tabindex="-1" role="dialog" aria-hidden="true">    <div class="pswp__bg"></div>    <div class="pswp__scroll-wrap">        <div class="pswp__container">            <div class="pswp__item"></div>            <div class="pswp__item"></div>            <div class="pswp__item"></div>        </div>        <div class="pswp__ui pswp__ui--hidden">            <div class="pswp__top-bar">                <div class="pswp__counter"></div>                <button class="pswp__button pswp__button--close" title="Close (Esc)"></button>                <button class="pswp__button pswp__button--share" title="Share"></button>                <button class="pswp__button pswp__button--fs" title="Toggle fullscreen"></button>                <button class="pswp__button pswp__button--zoom" title="Zoom in/out"></button>                <div class="pswp__preloader">                    <div class="pswp__preloader__icn">                      <div class="pswp__preloader__cut">                        <div class="pswp__preloader__donut"></div>                      </div>                    </div>                </div>            </div>            <div class="pswp__share-modal pswp__share-modal--hidden pswp__single-tap">                <div class="pswp__share-tooltip"></div>             </div>            <button class="pswp__button pswp__button--arrow--left" title="Previous (arrow left)">            </button>            <button class="pswp__button pswp__button--arrow--right" title="Next (arrow right)">            </button>            <div class="pswp__caption">                <div class="pswp__caption__center"></div>            </div>        </div>    </div></div>';
	var $pswpRoot = null;

	var gallery = null;

	function openPhotoswipe($container, $clickedLink) {
		if (!$pswpRoot) {
			$pswpRoot = $(pswpSource);
			$pswpRoot.appendTo($("body"));
		}

		items = [];

		var clickedIndex = 0;

		$container.find('a.item').each(function(index) {
			var $this = $(this);
			if ($clickedLink[0] == this) {
				clickedIndex = index;
			}
			items.push({
				'msrc': $this.find('.image img').attr('src'),
				'w': parseInt($this.attr('data-target-width')),
				'h': parseInt($this.attr('data-target-height')),
				'src': $this.attr('href'),
				'title': '<div class="name">' + $this.find('.name').html() + '</div>'
					+ '<div class="info">' + $this.find('.info').html() + '</div>'
			});
		});

		options = {
			index: clickedIndex,
			showHideOpacity: true,
			timeToIdle: 100000,
			timeToIdleOutside: 100000,
			bgOpacity: 0.8,
			loop: false,
			fullscreenEl: false,
			shareEl: false,
			//tapToClose: true,
			getThumbBoundsFn: function(index) {
				var $image = $container.find('a.item').eq(index).find('.image');
				var pos = {
					x: $image.offset().left,
					y: $image.offset().top,
					w: $image.width()
				}
				console.log('pos:', $image, pos);
				return pos;
			}
		};

		gallery = new PhotoSwipe(
			$pswpRoot[0],
			PhotoSwipeUI_Default,
			items,
			options
		);

		gallery.init();

	}

	$.fn.initReferences = function(params) {

		params = params || {};

		var _this = this;

		this.addClass('unscrolled');
		this.waypoint(function() {

			_this.removeClass('unscrolled').addClass('scrolled');

		}, {
			'offset': function() {
				return (Waypoint.viewportHeight() - 200);
			}
		});


		if (params.swiper) {
			_this.find('.swiper-container').swiper({
				freeMode: true,
				freeModeSticky: true,
				freeModeMomentum: true,
				freeModeMomentumRatio: 0.4,
				nextButton: _this.find('.reference-next')[0],
				prevButton: _this.find('.reference-prev')[0],
				pagination: _this.find('.pagination')[0],
				paginationClickable: true,
				slidesPerView: 'auto'
			});
		}

		if (params.clickable) {
			_this.find('a.item').click(function() {
				openPhotoswipe(_this, $(this));
				return false;
			});
		}



		return this;

	}

})(jQuery);
