(function($) {

	$.fn.initReasons = function() {

		this.parallax({
			imageSrc: this.attr('data-pax-src'),
			speed: 0.3
		});

		// this.css('background-color', 'transparent');
		// this.css('background-image', 'none');

		return this;

	};

})(jQuery);
